import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Toggle from 'react-toggle'

export default function FormInput({ label, value, onChange, classes, type, multiple, selectWithDefault, selectEntities, keyAttr, valueAttr, outputAttr, outputFunc, placeholder, min }) {
  const renderInputField = () => {
    if (type === 'select') {
      return (
        <Select
          multiple={ multiple }
          value={ value || '-' }
          onChange={ onChange }
        >
          { selectWithDefault &&
            <MenuItem
              key={0}
              value='-'
            >
              -
            </MenuItem>
          }
          {selectEntities.map((entity) => (
            <MenuItem
              key={entity[keyAttr]}
              value={entity[valueAttr]}
            >
              { outputAttr ? entity[outputAttr] : outputFunc(entity) }
            </MenuItem>
          ))}
        </Select>
      )
    } else if (type === 'toggle' ) {
      return (
        <Toggle
          icons={{ unchecked: null }}
          className='react-toggle small'
          checked={ value }
          onChange={ onChange }
        />
      )
    } else if (type === 'number' ) {
      return (
        <input
          type={'number'}
          className='form-control form-control-solid'
          value={ value }
          onChange={ onChange }
          placeholder={ placeholder }
          min={ min || 0 }
        />
      )
    } else {
      return (
        <input
          type={type || "text"}
          className='form-control form-control-solid'
          value={ value }
          onChange={ onChange }
          placeholder={ placeholder }
        />
      )
    }
  }

  return (
    <div className={ `flex-box flex-column ${ classes || '' }`}>
      { label &&
        <label className='fs-6 fw-semibold form-label mt-3'>
          { label }
        </label>
      }
      { renderInputField() }
    </div>
  )
}
