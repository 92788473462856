import React from 'react'

export default function PreviewOrder({ orderSummaryPath, merchantLogo }) {
  return (
    <div className='tokenized-link-wrapper flex-box flex-column content-center items-center' style={{ height: '100vh' }}>
      { merchantLogo &&
        <div className='ticket-logotype'>
          <img src={ merchantLogo } />
        </div>
      }

      <a href={orderSummaryPath} target="_blank" className="pointer btn btn-primary" rel="external nofollow noindex">
        { Website.translations.downloadTickets }
      </a>
    </div>
  )
}
