import React, { useState, useEffect, useRef } from 'react'
import SaveFixedButton from '../shared/SaveFixedButton'
import Tabs from '../shared/Tabs'
import StockItems from './StockItems'
import CustomSpaceStockItems from './CustomSpaceStockItems'
import Tags from './Tags'
import General from './General'
import Seasons from './Seasons'
import Options from './Options'
import moment from 'moment'
import MediaUploader from '../shared/MediaUploader'
import ModalAlert from '../shared/ModalAlert'
import CreateButton from '../shared/CreateButton'
import LanguageHandler from '../shared/LanguageHandler'

export default function EditTicket({
  appProps, currentTicket, updatePath, taxes, seasons, seasonsPath, locations,
  locationGroups, languages, initialTags, stockType, mediaPath, validateFilePath,
  createMediaNoUploadPath, mediaGalleryProps, space, spaceSelectedColumns
}) {
  const usedLocales = currentTicket.translations.map(translation => translation.locale)
  const initialUsedLanguages = languages.filter(language => usedLocales.indexOf(language.code) > -1 )
  const currentLanguage = initialUsedLanguages.find(language => language.code === appProps.currentLocale) || initialUsedLanguages[0] || languages[0]

  const [ticket, setTicket]                         = useState(currentTicket)
  const [usedLanguages, setUsedLanguages]           = useState(initialUsedLanguages.length ? initialUsedLanguages : [currentLanguage])
  const [selectedLanguage, setSelectedLanguage]     = useState(currentLanguage.code)
  const [saveButtonRef, setSaveButtonRef]           = useState(null)
  const [usedLocation, setUsedLocation]             = useState(currentTicket.locationId)
  const [usedLocationGroup, setUsedLocationGroup]   = useState(currentTicket.locationGroupId)
  const [translations, setTranslations]             = useState(() => getInitialTranslations(ticket))
  const [seasonalRates, setSeasonalRates]           = useState(initializeRates(currentTicket))
  const [tags, setTags]                             = useState(initialTags)
  const [usedTags, setUsedTags]                     = useState(currentTicket.tags.map(tag => tag.apiUid))
  const [savedTaxes, setSavedTaxes]                 = useState(taxes)
  const [newTaxes, setNewTaxes]                     = useState([])
  const [currentTab, setCurrentTab]                 = useState('general')
  const [stockItems, setStockItems]                 = useState(currentTicket.stockItems)
  const [options, setOptions]                       = useState(currentTicket.options)
  const [fields, setFields]                         = useState({
    published: ticket.published || false,
    price: ticket.price.toString(),
    tax: ticket.tax?.id || '',
    skipDatePick: ticket.skipDatePick || false
  })
  const [mediaUploader, setMediaUploader] = useState(null)
  const [images, setImages] = useState(currentTicket.images)
  const [showUploadAlert, setShowUploadAlert] = useState(false)
  const [newlyAddedOptions, setNewlyAddedOptions] = useState([])

  const didMountRef = useRef(false)
  const priceRegex = /^(0|[1-9]\d*)?([.,]{1}\d{0,2})?$/

  const optionTranslations = getInitialOptionTranslations(options)
  const optionTranslatableContent = optionTranslations?.map((translation, index) => (
    {
      items: translation,
      setItems: updatedItems => {
        const updatedTranslations = Object.keys(updatedItems).map(key => ({
          locale: key,
          title: updatedItems[key].title,
          subtitle: updatedItems[key].subtitle,
          id: updatedItems[key].id,
          _destroy: updatedItems[key]._destroy
        }))

        handleOptionChange('translations', index, updatedTranslations)
      },
      initialValues: { title: '', subtitle: '', id: null }
    }
  ))

  const translatableContent = [
    { items: translations, setItems: setTranslations, initialValues: { title: '', description: '' } },
    ...optionTranslatableContent
  ]

  const extraRemoveLanguageCallback = _locale => {
    saveButtonRef.setSaveableStatus()
  }

  useEffect( () => {
    if (didMountRef.current) {
      saveButtonRef.setSaveableStatus()
    } else {
      didMountRef.current = true
    }
  }, [images])

  function initializeRates(ticket) {
    return seasons.map(item => {
      const ticketRate = ticket.ticketSeasonPrices.find(seasonPrice => seasonPrice.season.id === item.id)
      return {
        id: ticketRate?.id || '',
        seasonId: item.id,
        price: ticketRate?.price || ''
      }
    })
  }

  const tabs = [
    { key: 'general', title: Website.translations.general },
    { key: 'options', title: Website.translations.options },
    { key: 'stockItems', title: Website.translations.stock },
    // { key: 'tags', title: Website.translations.tags },
  ]

  if (stockType !== 'Stock::CustomSpace') {
    tabs.push({ key: 'seasons', title: Website.translations.seasonalRates },)
  }

  function getInitialTranslations(item) {
    const initialTranslations = {}
    item.translations.map(translation => {
      initialTranslations[translation.locale] = {
        title: translation.title,
        description: translation.description,
        terms: translation.terms,
        id: translation.id
      }
    })

    return initialTranslations
  }

  function getInitialOptionTranslations(items) {
    const initialTranslations = []
    items.forEach((item, index) => {
      initialTranslations[index] = {}
      item.translations.forEach(translation => {
        initialTranslations[index][translation.locale] = {
          title: translation.title,
          subtitle: translation.subtitle,
          id: translation.id
        }
      })
    })

    return initialTranslations
  }

  const handleLocationChange = e => {
    setUsedLocation(e.target.value)
    saveButtonRef.setSaveableStatus()
  }

  const handleLocationGroupChange = e => {
    setUsedLocationGroup(e.target.value)
    saveButtonRef.setSaveableStatus()
  }

  const handleChange = (key, e) => {
    let { value } = e.target
    if (key === 'price') {
      value = value.replace(',', '.')
      if (!priceRegex.test(value)) {
        return
      }
    }

    setFields(prevState => ({
      ...prevState,
      [key]: ['published', 'skipDatePick'].includes(key) ? !prevState[key] : value
    }))
    saveButtonRef.setSaveableStatus()
  }

  const updateTags = e => {
    setUsedTags(e.target.value)
    saveButtonRef.setSaveableStatus()
  }

  const addNewTag = tag => {
    setTags(prevState => [...prevState, tag])
    setUsedTags(prevState => [...prevState, tag.apiUid])

    saveButtonRef.setSaveableStatus()
  }

  const handleOptionChange = (key, index, change) => {
    let value = key === 'translations' ? change : change.target.value
    if (key === 'price') {
      value = value.replace(',', '.')
      if (!priceRegex.test(value)) {
        return
      }

      if (stockType === 'Stock::CustomSpace' && newlyAddedOptions.map(item => item.id).includes(options[index].id)) {
        const oldPrice = options[index].price

        setStockItems(prevState => prevState.map(item => ({
          ...item,
          stockable: {
            ...item.stockable,
            zoneOptionPrices: item.stockable.zoneOptionPrices.map(arr => {
              const relevantItemIndex = arr.findIndex(el => el.ticketOptionId === options[index].id)

              return [
                ...arr.slice(0, relevantItemIndex),
                { ...arr[relevantItemIndex], price: arr[relevantItemIndex].price == oldPrice ? value : arr[relevantItemIndex].price },
                ...arr.slice(relevantItemIndex + 1)
              ]
            })
          }
        })))
      }
    }
    setOptions(prevState => {
      const updatedItem = { ...prevState[index], [key]: value }

      return [
        ...prevState.slice(0, index),
        updatedItem,
        ...prevState.slice(index + 1)
      ]
    })
    saveButtonRef.setSaveableStatus()
  }

  const handleOptionTranslationChange = (key, locale, index, e) => {
    const { value } = e.target
    setOptions(prevState => {
      const relevantTranslationIndex = prevState[index].translations.findIndex(translation => translation.locale === locale)
      const updatedItem = {
        ...prevState[index],
        translations: [
          ...prevState[index].translations.slice(0, relevantTranslationIndex),
          { ...prevState[index].translations[relevantTranslationIndex], [key]: value },
          ...prevState[index].translations.slice(relevantTranslationIndex + 1)
        ]
      }

      return [
        ...prevState.slice(0, index),
        updatedItem,
        ...prevState.slice(index + 1)
      ]
    })
    saveButtonRef.setSaveableStatus()
  }

  const handleAddNewOption = (_e, languages) => {
    const newDummyId = -1 - newlyAddedOptions.length

    setOptions(prevState => [
      ...prevState,
      {
        title: '',
        subtitle: '',
        id: newDummyId,
        translations: languages.map(language => (
          { title: '', subtitle: '', locale: language.code, id: null }
        )),
        price: 0,
        ticketOptionSeasonPrices: seasons.map(season => (
           {
             id: '',
             price: '',
             season: {
               id: season.id
             }
           }
        ))
      }
    ])

    setNewlyAddedOptions(prevState => [
      ...prevState,
      {
        id: newDummyId,
      }
    ])

    if (stockType === 'Stock::CustomSpace') {
      setStockItems(prevState => prevState.map(item => ({
        ...item,
        stockable: {
          ...item.stockable,
          zoneOptionPrices: item.stockable.zoneOptionPrices.map((arr, index) => {
            const newItem = {
              // zoneId: arr[0].zoneId,
              zoneId: item.stockable.zonePrices[index].zoneId,
              price: 0,
              ticketOptionId: newDummyId
            }

            return [...arr, newItem]
          })
        }
      })))
    }

    saveButtonRef.setSaveableStatus()
  }

  const handleDeleteOption = (_e, index) => {
    setOptions(prevState => [
      ...prevState.slice(0, index),
      ...prevState.slice(index + 1),
      { ...prevState[index], _destroy: true }
    ])

    if (stockType === 'Stock::CustomSpace') {
      setStockItems(prevState => prevState.map(item => ({
        ...item,
        stockable: {
          ...item.stockable,
          zoneOptionPrices: item.stockable.zoneOptionPrices.map(arr => [
            ...arr.slice(0, index),
            ...arr.slice(index + 1),
            { ...arr[index], _destroy: true }
          ])
        }
      })))
    }

    saveButtonRef.setSaveableStatus()
  }

  const handleOptionSeasonPriceChange = (index, seasonId, e) => {
    let { value } = e.target
    value = value.replace(',', '.')
    if (!priceRegex.test(value)) {
      return
    }

    const seasonIndex = options[index].ticketOptionSeasonPrices.findIndex(seasonPrice => seasonPrice.season.id === seasonId)

    setOptions(prevState => [
      ...prevState.slice(0, index),
      {
        ...prevState[index],
        ticketOptionSeasonPrices: [
          ...prevState[index].ticketOptionSeasonPrices.slice(0, seasonIndex),
          { id: prevState[index].ticketOptionSeasonPrices[seasonIndex]?.id, price: value, season: { id: seasonId } },
          ...prevState[index].ticketOptionSeasonPrices.slice(seasonIndex + 1)
        ]
      },
      ...prevState.slice(index + 1)
    ])
    saveButtonRef.setSaveableStatus()
  }

  const save = () => {
    if (mediaUploader.isUploading()) {
      setShowUploadAlert(true)

    } else {
      const { tax } = fields
      const rates = seasonalRates.map(rate => rate.price.toString().endsWith('.') ? { ...rate, price: rate.price.slice(0, -1) } : rate)
      const fd = new FormData()
      Object.keys(translations).forEach((locale, index) => {
        fd.append(`ticket[translations_attributes][${ index }][title]`, translations[locale].title || '')
        fd.append(`ticket[translations_attributes][${ index }][description]`, translations[locale].description || '')
        fd.append(`ticket[translations_attributes][${ index }][terms]`, translations[locale].terms || '')
        fd.append(`ticket[translations_attributes][${ index }][locale]`, locale)

        if (translations[locale].id) {
          fd.append(`ticket[translations_attributes][${ index }][id]`, translations[locale].id)
        }
        if (translations[locale]._destroy) {
          fd.append(`ticket[translations_attributes][${ index }][_destroy]`, translations[locale]._destroy)
        }
      })

      stockItems.forEach((item, index) => {
        if (item.stockable.type === "Stock::TimeSlot") {
          if (item.seasonId) {
            fd.append(`time_slot[${ index }][season_id]`, item.seasonId)
          }

          if (item.id && item.id > 0) {
            fd.append(`time_slot[${ index }][id]`, item.stockable.id)
          }

          if (item._destroy) {
            fd.append(`time_slot[${ index }][_destroy]`, item._destroy)
          } else {
            fd.append(`time_slot[${ index }][stockable][slot_from]`, JSON.stringify(moment(item.stockable.slotFrom).local().format('h:mm a')))
            fd.append(`time_slot[${ index }][stockable][slot_to]`, JSON.stringify(moment(item.stockable.slotTo).local().format('h:mm a')))
            fd.append(`time_slot[${ index }][stockable][quantity]`, item.stockable.quantity)
          }
        }
        if (item.stockable.type === "Stock::SimpleQuantity") {
          if (item.seasonId) {
            fd.append(`simple_quantity[${ index }][season_id]`, item.seasonId)
          }

          if (item.id && item.id > 0) {
            fd.append(`simple_quantity[${ index }][id]`, item.stockable.id)
          }

          if (item._destroy) {
            fd.append(`simple_quantity[${ index }][_destroy]`, item._destroy)
          } else {
            fd.append(`simple_quantity[${ index }][stockable][quantity]`, item.stockable.quantity)
          }
        }
        if (item.stockable.type === "Stock::CustomSpace") {
          if (item.id && item.id > 0) {
            fd.append(`custom_space_stock[${ index }][id]`, item.stockable.id)
          }

          if (item._destroy) {
            fd.append(`custom_space_stock[${ index }][_destroy]`, item._destroy)
          } else {
            fd.append(`custom_space_stock[${ index }][stockable][date]`, moment(item.stockable.date).format('YYYY-MM-DD'))
            fd.append(`custom_space_stock[${ index }][stockable][slot_from]`, typeof item.stockable.slotFrom === 'object' ? moment(item.stockable.slotFrom).local().format('HH:mm') : item.stockable.slotFrom)
            fd.append(`custom_space_stock[${ index }][stockable][slot_to]`, typeof item.stockable.slotTo === 'object' ? moment(item.stockable.slotTo).local().format('HH:mm') : item.stockable.slotTo)
            fd.append(`custom_space_stock[${ index }][position]`, index)
            item.stockable.zonePrices.forEach((zonePrice, priceIndex) => {
              fd.append(`custom_space_stock[${ index }][stockable][zone_prices][${ priceIndex }][price]`, zonePrice.price)
              fd.append(`custom_space_stock[${ index }][stockable][zone_prices][${ priceIndex }][space_zone_id]`, zonePrice.zoneId)
              if (zonePrice.id) {
                fd.append(`custom_space_stock[${ index }][stockable][zone_prices][${ priceIndex }][id]`, zonePrice.id)
              }
            })
            fd.append(`custom_space_stock[${ index }][stockable][blocked_zones]`, JSON.stringify(item.stockable.blockedZones))
          }
        }
      })

      options.forEach((item, index) => {
        if (item.id && item.id > 0) {
          fd.append(`ticket[options_attributes][${ index }][id]`, item.id)
        }

        fd.append(`ticket[options_attributes][${ index }][position]`, index)
        if (item._destroy) {
          fd.append(`ticket[options_attributes][${ index }][_destroy]`, item._destroy)
        } else {
          fd.append(`ticket[options_attributes][${ index }][price]`, item.price)
          item.translations.forEach((translation, translationIndex) => {
            fd.append(`ticket[options_attributes][${ index }][translations_attributes][${ translationIndex }][title]`, translation.title)
            fd.append(`ticket[options_attributes][${ index }][translations_attributes][${ translationIndex }][subtitle]`, translation.subtitle)
            fd.append(`ticket[options_attributes][${ index }][translations_attributes][${ translationIndex }][locale]`, translation.locale)

            if (translation.id) {
              fd.append(`ticket[options_attributes][${ index }][translations_attributes][${ translationIndex }][id]`, translation.id)
            }

            if (translation._destroy) {
              fd.append(`ticket[options_attributes][${ index }][translations_attributes][${ translationIndex }][_destroy]`, translation._destroy)
            }
          })

          item.ticketOptionSeasonPrices.forEach((seasonPrice, seasonIndex) => {
            if (seasonPrice.price !== '' || seasonPrice.id !== '') {
              if (seasonPrice.id) {
                fd.append(`ticket[options_attributes][${ index }][ticket_option_season_prices_attributes][${ seasonIndex }][id]`, seasonPrice.id || '')
              }
              if (seasonPrice.price === '') {
                fd.append(`ticket[options_attributes][${ index }][ticket_option_season_prices_attributes][${ seasonIndex }][_destroy]`, true)
              }
              fd.append(`ticket[options_attributes][${ index }][ticket_option_season_prices_attributes][${ seasonIndex }][price]`, seasonPrice.price)
              fd.append(`ticket[options_attributes][${ index }][ticket_option_season_prices_attributes][${ seasonIndex }][season_id]`, seasonPrice.season?.id || null)
            }
          })

          if (stockItems.length && stockItems[0].stockable.type === 'Stock::CustomSpace') {
            stockItems.forEach((stockItem, stockItemIndex) => {
              const { zoneOptionPrices } = stockItem.stockable
              zoneOptionPrices.forEach((zoneOptionPrice, zoneOptionPriceIndex) => {
                if (stockItem._destroy) {
                  fd.append(`ticket[options_attributes][${ index }][zone_option_prices_attributes][${ (stockItemIndex * zoneOptionPrices.length) + zoneOptionPriceIndex }][_destroy]`, stockItem._destroy)
                } else {
                  fd.append(`ticket[options_attributes][${ index }][zone_option_prices_attributes][${ (stockItemIndex * zoneOptionPrices.length) + zoneOptionPriceIndex }][space_zone_id]`, zoneOptionPrice[index].zoneId)
                  fd.append(`ticket[options_attributes][${ index }][zone_option_prices_attributes][${ (stockItemIndex * zoneOptionPrices.length) + zoneOptionPriceIndex }][price]`, zoneOptionPrice[index].price)
                  if (zoneOptionPrice[index].id) {
                    fd.append(`ticket[options_attributes][${ index }][zone_option_prices_attributes][${ (stockItemIndex * zoneOptionPrices.length) + zoneOptionPriceIndex }][id]`, zoneOptionPrice[index].id)
                  }
                }
              })
            })
          }
        }

      })

      fd.append('ticket[published]', fields.published)
      fd.append('ticket[skip_date_pick]', fields.skipDatePick)

      if (Number.isNaN(parseInt(tax)) || tax > -1) {
        fd.append('ticket[tax_id]', tax)
      } else {
        const selectedTax = newTaxes.find(newTax => newTax.id === tax)

        fd.append('new_tax[title]', selectedTax.title)
        fd.append('new_tax[rate]', selectedTax.rate)
        fd.append('new_tax[included_in_price]', selectedTax.includedInPrice === 'included')
      }

      fd.append('new_taxes', JSON.stringify(newTaxes.filter(newTax => newTax.id !== tax)))

      if (usedLocation) {
        fd.append('ticket[location_id]', usedLocation)
      }
      if (usedLocationGroup) {
        fd.append('ticket[location_group_id]', usedLocationGroup)
      }

      if (Number.isNaN(parseInt(fields.price.slice(-1)))){
        fd.append('ticket[price]', fields.price.slice(0, -1))
      } else {
        fd.append('ticket[price]', fields.price)
      }

      fd.append('tags', JSON.stringify(usedTags.map(uid => tags.find(tag => tag.apiUid === uid))))

      fd.append('seasonal_rates', JSON.stringify(rates.filter(rate => rate.id !== '' || rate.price !== '')))

      fd.append('assets', JSON.stringify(
        images.filter( img => !img.blob ).map( img => ({
          id: img.id,
          media_id: img.mediaId,
          uid: img.uid
        }))
      ))

      saveButtonRef.setSavingStatus()

      Rails.ajax({
        type: 'PATCH',
        url: updatePath,
        dataType: 'json',
        data: fd,
        success: res => {
          saveButtonRef.setSuccessStatus()
          setSavedTaxes(res.taxes)
          setNewTaxes([])
          setTicket(res.ticket)
          setFields(prevState => ({ ...prevState, tax: res.ticket.tax?.id || '' }))
          setStockItems(res.ticket.stockItems)
          setSeasonalRates(initializeRates(res.ticket))
          setTranslations(getInitialTranslations(res.ticket))
          setOptions(res.ticket.options)
          setNewlyAddedOptions([])

          if (res.assets && res.assets.length) {
            setImages( prevState => prevState.map( image => ({ ...image, id: res.assets.find( item => item.uid === image.uid ).id }) ) )
          }
        },
        error: res => {
          saveButtonRef.setErrorStatusWithMessage(res.error)
        }
      })
    }
  }

  const handleSeasonalRateChange = (id, seasonId, e) => {
    let { value } = e.target
    value = value.replace(',', '.')
    if (!priceRegex.test(value)) {
      return
    }

    setSeasonalRates(prevState => {
      const index = prevState.findIndex(rate => rate.seasonId === seasonId)
      return [
        ...prevState.slice(0, index),
        { id: id, seasonId: seasonId, price: value },
        ...prevState.slice(index + 1)
      ]
    })
    saveButtonRef.setSaveableStatus()
  }

  const updateStockItems = (stockItems, setSaveableStatus = true) => {
    setStockItems(stockItems)
    if (setSaveableStatus) {
      saveButtonRef.setSaveableStatus()
    }
  }

  return (
    <>
      <div>
        <CreateButton
          pageTitle={ currentTicket.title ? `${ currentTicket.title }` : Website.translations.ticket }
          breadcrumbs={ appProps.breadcrumbs }
        />

        <LanguageHandler
          languages={ languages }
          usedLanguages={ usedLanguages }
          setUsedLanguages={ setUsedLanguages }
          selectedLanguage={ selectedLanguage }
          setSelectedLanguage={ setSelectedLanguage }
          translatableContent={ translatableContent }
          extraRemoveLanguageCallback={ extraRemoveLanguageCallback }
        />

        <Tabs
          tabs={ tabs }
          currentTab={ currentTab }
          setCurrentTab={ tab => setCurrentTab(tab) }
        />
        { currentTab === 'general' &&
          <General
            taxes={ savedTaxes }
            locations={ locations }
            locationGroups={ locationGroups }
            usedLocation={ usedLocation }
            usedLocationGroup={ usedLocationGroup }
            handleLocationChange={ handleLocationChange }
            handleLocationGroupChange={ handleLocationGroupChange }
            fields={ fields }
            translations={ translations }
            setTranslations={ setTranslations }
            newTaxes={ newTaxes }
            setNewTaxes={ setNewTaxes }
            handleChange={ handleChange }
            saveButtonRef={ saveButtonRef }
            selectedLanguage={ selectedLanguage }
            mediaPath={ mediaPath }
            images={ images }
            setImages={ setImages }
            mediaUploader={ mediaUploader }
            stockType={ stockType }
          />
        }

        { currentTab === 'options' &&
          <Options
            options={ options }
            handleOptionChange={ handleOptionChange }
            handleOptionTranslationChange={ handleOptionTranslationChange }
            handleAddNewOption={ handleAddNewOption }
            handleDeleteOption={ handleDeleteOption }
            handleOptionSeasonPriceChange={ handleOptionSeasonPriceChange }
            seasons={ seasons }
            seasonsPath={ seasonsPath }
            selectedLanguage={ selectedLanguage }
            usedLanguages={ usedLanguages }
            translations={ optionTranslations }
            saveButtonRef={ saveButtonRef }
            setOptions={ setOptions }
            setStockItems={ setStockItems }
            stockType={ stockType }
          />
        }

        { currentTab === 'stockItems' &&
          <>
            { stockType !== 'Stock::CustomSpace'
              ? <StockItems
                  appProps={ appProps }
                  stockItems={ stockItems }
                  stockFilterFunc= { item => !item.seasonId && !item._destroy }
                  updateStockItems={ updateStockItems }
                  seasonId={ null }
                  stockType={ stockType }
                />
              : <CustomSpaceStockItems
                  appProps={ appProps }
                  stockItems={ stockItems }
                  stockFilterFunc= { item => !item.seasonId && !item._destroy }
                  space={ { ...space, zones: space.zones.map(zone => ({ ...zone, mapping: JSON.parse(zone.mapping) })) } }
                  updateStockItems={ updateStockItems }
                  defaultPrice={ fields.price }
                  options={ options.filter(option => !option._destroy) }
                  selectedLanguage={ selectedLanguage }
                  spaceSelectedColumns={ spaceSelectedColumns }
                />
            }
          </>
        }

        {/* { currentTab === 'tags' &&
          <Tags
            tags={ tags }
            usedTags={ usedTags }
            updateTags={ updateTags }
            addNewTag={ addNewTag }
          />
        } */}

        { currentTab === 'seasons' &&
          <Seasons
            seasons={ seasons }
            stockItems={ stockItems }
            updateStockItems={ updateStockItems }
            seasonalRates={ seasonalRates }
            handleSeasonalRateChange={ handleSeasonalRateChange }
            stockType={ stockType }
            itemsPath={ seasonsPath }
          />
        }
      </div>

      { showUploadAlert &&
        <ModalAlert
          alert={ Website.translations.media.uploadAlert }
          cancelText={ Website.translations.close }
          onClose={ () => setShowUploadAlert(false) }
        />
      }

      <MediaUploader
        ref={ ref => setMediaUploader(ref) }
        mediaPath={ mediaPath }
        validateFilePath={ validateFilePath }
        createMediaNoUploadPath={ createMediaNoUploadPath }
        mediaGalleryProps={ mediaGalleryProps }
      />

      <SaveFixedButton
        onSave={ save }
        entity={ Website.translations.ticket }
        ref={ ref => setSaveButtonRef(ref) }
      />
    </>
  )
}
